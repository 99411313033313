@import "../../shared/ui-components/styles/variables";
@import "./variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('./#{$icomoon-font-family}.ttf?luezm6') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="al-ico-"], [class*=" al-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.al-ico-link-off {
  &:before {
    content: $al-ico-link-off; 
  }
}
.al-ico-policy {
  &:before {
    content: $al-ico-policy;
  }
}
.al-ico-info-group {
  &:before {
    content: $al-ico-info-group;
  }
}
.al-ico-advanced-filters {
  &:before {
    content: $al-ico-advanced-filters;
  }
}
.al-ico-triggers-scheduled {
  &:before {
    content: $al-ico-triggers-scheduled;
  }
}
.al-ico-queue {
  &:before {
    content: $al-ico-queue;
  }
}
.al-ico-link-plus {
  &:before {
    content: $al-ico-link-plus;
  }
}
.al-ico-drag-vertical {
  &:before {
    content: $al-ico-drag-vertical;
  }
}
.al-ico-drag-horizontal {
  &:before {
    content: $al-ico-drag-horizontal;
  }
}
.al-ico-admin-support {
  &:before {
    content: $al-ico-admin-support;
  }
}
.al-ico-scatter-view {
  &:before {
    content: $al-ico-scatter-view;
  }
}.al-ico-scatter-view {
  &:before {
    content: $al-ico-scatter-view;
  }
}
.al-ico-schedulers {
  &:before {
    content: $al-ico-schedulers;
  }
}
.al-ico-triggers {
  &:before {
    content: $al-ico-triggers;
  }
}
.al-ico-autoscalers {
  &:before {
    content: $al-ico-autoscalers;
  }
}
.al-ico-automation {
  &:before {
    content: $al-ico-automation;
  }
}
.al-ico-charts-view:before {
  content: "\e9f9";
}
.al-ico-compact-view:before {
  content: "\e9fa";
}
.al-ico-tune {
  &:before {
    content: $al-ico-tune;
  }
}

.al-ico-equal-outline {
  &:before {
    content: $al-ico-equal-outline;
  }
}
.al-ico-maximize {
  &:before {
    content: $al-ico-maximize;
  }
}
.al-ico-gpu-card {
  &:before {
    content: $al-ico-gpu-card;
  }
}
.al-ico-legend {
  &:before {
    content: $al-ico-legend;
  }
}
.al-ico-model-filled {
  &:before {
    content: $al-ico-model-filled;
  }
}
.al-ico-type-report {
  &:before {
    content: $al-ico-type-report;
  }
}
.al-ico-info-circle-outline {
  &:before {
    content: $al-ico-info-circle-outline;
  }
}
.al-ico-ghost {
  &:before {
    content: $al-ico-ghost;
  }
}
.al-ico-flat-view {
  &:before {
    content: $al-ico-flat-view;
  }
}
.al-ico-camera {
  &:before {
    content: $al-ico-camera;
  }
}
.al-ico-markdown {
  &:before {
    content: $al-ico-markdown;
  }
}
.al-ico-hor-expand {
  &:before {
    content: $al-ico-hor-expand;
  }
}
.al-ico-hor-minimize {
  &:before {
    content: $al-ico-hor-minimize;
  }
}
.al-ico-pdf {
  &:before {
    content: $al-ico-pdf;
  }
}
.al-ico-reports {
  &:before {
    content: $al-ico-reports;
  }
}
.al-ico-gpu {
  &:before {
    content: $al-ico-gpu;
  }
}
.al-ico-project-path {
  &:before {
    content: $al-ico-project-path;
  }
}
.al-ico-tree-view {
  &:before {
    content: $al-ico-tree-view;
  }
}
.al-ico-sort-asc {
  &:before {
    content: $al-ico-sort-asc;
  }
}
.al-ico-sort-desc {
  &:before {
    content: $al-ico-sort-desc;
  }
}
.al-ico-grid-view {
  &:before {
    content: $al-ico-grid-view;
  }
}
.al-ico-connect {
  &:before {
    content: $al-ico-connect;
  }
}
.al-ico-disconnect {
  &:before {
    content: $al-ico-disconnect;
  }
}
.al-ico-trash-all {
  &:before {
    content: $al-ico-trash-all;
  }
}
.al-ico-drag {
  &:before {
    content: $al-ico-drag;
  }
}
.al-ico-py {
  &:before {
    content: $al-ico-py;
  }
}
.al-ico-file {
  &:before {
    content: $al-ico-file;
  }
}
.al-ico-txt {
  &:before {
    content: $al-ico-txt;
  }
}
.al-ico-pkl {
  &:before {
    content: $al-ico-pkl;
  }
}
.al-ico-image {
  &:before {
    content: $al-ico-image;
  }
}
.al-ico-zip {
  &:before {
    content: $al-ico-zip;
  }
}
.al-ico-code-file {
  &:before {
    content: $al-ico-code-file;
  }
}
.al-ico-audio {
  &:before {
    content: $al-ico-audio;
  }
}
.al-ico-upload {
  &:before {
    content: $al-ico-upload;
  }
}
.al-ico-min-panel {
  &:before {
    content: $al-ico-min-panel;
  }
}
.al-ico-max-panel {
  &:before {
    content: $al-ico-max-panel;
  }
}
.al-ico-datasets {
  &:before {
    content: $al-ico-datasets;
  }
}
.al-ico-t-logo-b {
  &:before {
    content: $al-ico-t-logo-b;
  }
}
.al-ico-bars-menu {
  &:before {
    content: $al-ico-bars-menu;
  }
}
.al-ico-home {
  &:before {
    content: $al-ico-home;
  }
}
.al-ico-projects {
  &:before {
    content: $al-ico-projects;
  }
}
.al-ico-queues {
  &:before {
    content: $al-ico-queues;
  }
}
.al-ico-annotator {
  &:before {
    content: $al-ico-annotator;
  }
}
.al-ico-account {
  &:before {
    content: $al-ico-account;
  }
}
.al-ico-compare {
  &:before {
    content: $al-ico-compare;
  }
}
.al-ico-archive {
  &:before {
    content: $al-ico-archive;
  }
}
.al-ico-cloud-head {
  &:before {
    content: $al-ico-cloud-head;
  }
}
.al-ico-how-to {
  &:before {
    content: $al-ico-how-to;
  }
}
.al-ico-compare-c {
  &:before {
    content: $al-ico-compare-c;
  }
}
.al-ico-published-title {
  &:before {
    content: $al-ico-published-title;
  }
}
.al-ico-publish {
  &:before {
    content: $al-ico-publish;
  }
}
.al-ico-unpublish {
  &:before {
    content: $al-ico-unpublish;
  }
}
.al-ico-published {
  &:before {
    content: $al-ico-published;
  }
}
.al-ico-info {
  &:before {
    content: $al-ico-info;
  }
}
.al-ico-warn {
  &:before {
    content: $al-ico-warn;
  }
}
.al-ico-settings {
  &:before {
    content: $al-ico-settings;
  }
}
.al-ico-trash {
  &:before {
    content: $al-ico-trash;
  }
}
.al-ico-arrow-from-right {
  &:before {
    content: $al-ico-arrow-from-right;
  }
}
.al-ico-arrow-from-left {
  &:before {
    content: $al-ico-arrow-from-left;
  }
}
.al-ico-arrow-to-bottom {
  &:before {
    content: $al-ico-arrow-to-bottom;
  }
}
.al-ico-arrow-to-top {
  &:before {
    content: $al-ico-arrow-to-top;
  }
}
.al-ico-training {
  &:before {
    content: $al-ico-training;
  }
}
.al-ico-test {
  &:before {
    content: $al-ico-test;
  }
}
.al-ico-testing {
  &:before {
    content: $al-ico-testing;
  }
}
.al-ico-type-training {
  &:before {
    content: $al-ico-type-training;
  }
}
.al-ico-type-testing {
  &:before {
    content: $al-ico-type-testing;
  }
}
.al-ico-type-data-processing {
  &:before {
    content: $al-ico-type-data-processing;
  }
}
.al-ico-type-qc {
  &:before {
    content: $al-ico-type-qc;
  }
}
.al-ico-type-service {
  &:before {
    content: $al-ico-type-service;
  }
}
.al-ico-type-optimizer {
  &:before {
    content: $al-ico-type-optimizer;
  }
}
.al-ico-type-monitor {
  &:before {
    content: $al-ico-type-monitor;
  }
}
.al-ico-type-inference {
  &:before {
    content: $al-ico-type-inference;
  }
}
.al-ico-type-application {
  &:before {
    content: $al-ico-type-application;
  }
}
.al-ico-type-controller {
  &:before {
    content: $al-ico-type-controller;
  }
}
.al-ico-type-custom {
  &:before {
    content: $al-ico-type-custom;
  }
}
.al-ico-how-to1 {
  &:before {
    content: $al-ico-how-to1;
  }
}
.al-ico-model {
  &:before {
    content: $al-ico-model;
  }
}
.al-ico-dialog-x {
  &:before {
    content: $al-ico-dialog-x;
  }
}
.al-ico-temp-image {
  &:before {
    content: $al-ico-temp-image;
  }
}
.al-ico-temp-list-alt {
  &:before {
    content: $al-ico-temp-list-alt;
  }
}
.al-ico-dog {
  &:before {
    content: $al-ico-dog;
  }
}
.al-ico-alert-purple {
  &:before {
    content: $al-ico-alert-purple;
  }
}
.al-ico-back {
  &:before {
    content: $al-ico-back;
  }
}
.al-ico-cat {
  &:before {
    content: $al-ico-cat;
  }
}
.al-ico-clone {
  &:before {
    content: $al-ico-clone;
  }
}
.al-ico-completed {
  &:before {
    content: $al-ico-completed;
  }
}
.al-ico-data-audit {
  &:before {
    content: $al-ico-data-audit;
  }
}
.al-ico-download-frames {
  &:before {
    content: $al-ico-download-frames;
  }
}
.al-ico-dropdown-arrow {
  &:before {
    content: $al-ico-dropdown-arrow;
  }
}
.al-ico-ellipse-icon {
  &:before {
    content: $al-ico-ellipse-icon;
  }
}
.al-ico-filter {
  &:before {
    content: $al-ico-filter;
  }
}
.al-ico-fit {
  &:before {
    content: $al-ico-fit;
  }
}
.al-ico-id {
  &:before {
    content: $al-ico-id;
  }
}
.al-ico-info-max {
  &:before {
    content: $al-ico-info-max;
  }
}
.al-ico-info-min {
  &:before {
    content: $al-ico-info-min;
  }
}
.al-ico-keypoint {
  &:before {
    content: $al-ico-keypoint;
  }
}
.al-ico-list-view {
  &:before {
    content: $al-ico-list-view;
  }
}
.al-ico-minus {
  &:before {
    content: $al-ico-minus;
  }
}
.al-ico-previous {
  &:before {
    content: $al-ico-previous;
  }
}
.al-ico-next {
  &:before {
    content: $al-ico-next;
  }
}
.al-ico-plus {
  &:before {
    content: $al-ico-plus;
  }
}
.al-ico-polygon {
  &:before {
    content: $al-ico-polygon;
  }
}
.al-ico-pytorch-icon {
  &:before {
    content: $al-ico-pytorch-icon;
  }
}
.al-ico-rectangle {
  &:before {
    content: $al-ico-rectangle;
  }
}
.al-ico-running {
  &:before {
    content: $al-ico-running;
  }
}
.al-ico-setup {
  &:before {
    content: $al-ico-setup;
  }
}
.al-ico-undo {
  &:before {
    content: $al-ico-undo;
  }
}
.al-ico-zoom-1-to-1 {
  &:before {
    content: $al-ico-zoom-1-to-1;
  }
}
.al-ico-zoom-in {
  &:before {
    content: $al-ico-zoom-in;
  }
}
.al-ico-zoom-out {
  &:before {
    content: $al-ico-zoom-out;
  }
}
.al-ico-search {
  &:before {
    content: $al-ico-search;
  }
}
.al-ico-zoom-to-fit {
  &:before {
    content: $al-ico-zoom-to-fit;
  }
}
.al-ico-reset {
  &:before {
    content: $al-ico-reset;
  }
}
.al-ico-import {
  &:before {
    content: $al-ico-import;
  }
}
.al-ico-export {
  &:before {
    content: $al-ico-export;
  }
}
.al-ico-between {
  &:before {
    content: $al-ico-between;
  }
}
.al-ico-next-batch {
  &:before {
    content: $al-ico-next-batch;
  }
}
.al-ico-prev-batch {
  &:before {
    content: $al-ico-prev-batch;
  }
}
.al-ico-back-to-top {
  &:before {
    content: $al-ico-back-to-top;
  }
}
.al-ico-redo {
  &:before {
    content: $al-ico-redo;
  }
}
.al-ico-download {
  &:before {
    content: $al-ico-download;
  }
}
.al-ico-edit {
  &:before {
    content: $al-ico-edit;
  }
}
.al-ico-pending {
  &:before {
    content: $al-ico-pending;
  }
}
.al-ico-aborted {
  &:before {
    content: $al-ico-aborted;
  }
}
.al-ico-success {
  &:before {
    content: $al-ico-success;
  }
}
.al-ico-alert-outline {
  &:before {
    content: $al-ico-alert-outline;
  }
}
.al-ico-github {
  &:before {
    content: $al-ico-github;
  }
}
.al-ico-description {
  &:before {
    content: $al-ico-description;
  }
}
.al-ico-line-expand {
  &:before {
    content: $al-ico-line-expand;
  }
}
.al-ico-user-logout {
  &:before {
    content: $al-ico-user-logout;
  }
}
.al-ico-documentation {
  &:before {
    content: $al-ico-documentation;
  }
}
.al-ico-frame-rule {
  &:before {
    content: $al-ico-frame-rule;
  }
}
.al-ico-source-rule {
  &:before {
    content: $al-ico-source-rule;
  }
}
.al-ico-get-link-dialog {
  &:before {
    content: $al-ico-get-link-dialog;
  }
}
.al-ico-iteration {
  &:before {
    content: $al-ico-iteration;
  }
}
.al-ico-mapping {
  &:before {
    content: $al-ico-mapping;
  }
}
.al-ico-labels {
  &:before {
    content: $al-ico-labels;
  }
}
.al-ico-augmentation {
  &:before {
    content: $al-ico-augmentation;
  }
}
.al-ico-filter-outlined {
  &:before {
    content: $al-ico-filter-outlined;
  }
}
.al-ico-input-data {
  &:before {
    content: $al-ico-input-data;
  }
}
.al-ico-help-outlined {
  &:before {
    content: $al-ico-help-outlined;
  }
}
.al-ico-email {
  &:before {
    content: $al-ico-email;
  }
}
.al-ico-tips {
  &:before {
    content: $al-ico-tips;
  }
}
.al-ico-caret-right {
  &:before {
    content: $al-ico-caret-right;
  }
}
.al-ico-currently-active {
  &:before {
    content: $al-ico-currently-active;
  }
}
.al-ico-complete {
  &:before {
    content: $al-ico-complete;
  }
}
.al-ico-access-key {
  &:before {
    content: $al-ico-access-key;
  }
}
.al-ico-alert {
  &:before {
    content: $al-ico-alert;
  }
}
.al-ico-users {
  &:before {
    content: $al-ico-users;
  }
}
.al-ico-exit-archive {
  &:before {
    content: $al-ico-exit-archive;
  }
}
.al-ico-calendar {
  &:before {
    content: $al-ico-calendar;
  }
}
.al-ico-time {
  &:before {
    content: $al-ico-time;
  }
}
.al-ico-add {
  &:before {
    content: $al-ico-add;
  }
}
.al-ico-time-colon {
  &:before {
    content: $al-ico-time-colon;
  }
}
.al-ico-regex {
  &:before {
    content: $al-ico-regex;
  }
}
.al-ico-filter-on .path1 {
  &:before {
    content: $al-ico-filter-on-path1;
    color: $neon-yellow; // don't overwrite
    // color: rgb(211, 255, 0);
  }
}
.al-ico-filter-on .path2 {
  &:before {
    content: $al-ico-filter-on-path2;
    margin-left: -1em;
    color: $blue-300; // don't overwrite
  }
}
.al-ico-filter-off {
  &:before {
    content: $al-ico-filter-off;
    color: $blue-400; // don't overwrite
  }
}
.al-ico-sort-off {
  &:before {
    content: $al-ico-sort-off;
    color: $blue-400; // don't overwrite
  }
}
.al-ico-sort-on-down .path1 {
  &:before {
    content: $al-ico-sort-on-down-path1;
    color: $blue-400; // don't overwrite
  }
}
.al-ico-sort-on-down .path2 {
  &:before {
    content: $al-ico-sort-on-down-path2;
    margin-left: -1em;
    color: $blue-200; // don't overwrite
  }
}
.al-ico-sort-on-up .path1 {
  &:before {
    content: $al-ico-sort-on-up-path1;
    color: $blue-400; // don't overwrite
  }
}
.al-ico-sort-on-up .path2 {
  &:before {
    content: $al-ico-sort-on-up-path2;
    margin-left: -1em;
    color: $blue-200; // don't overwrite
  }
}
.al-ico-arrow-left {
  &:before {
    content: $al-ico-arrow-left;
  }
}
.al-ico-arrow-right {
  &:before {
    content: $al-ico-arrow-right;
  }
}
.al-ico-step-backward {
  &:before {
    content: $al-ico-step-backward;
  }
}
.al-ico-step-forward {
  &:before {
    content: $al-ico-step-forward;
  }
}
.al-ico-backward {
  &:before {
    content: $al-ico-backward;
  }
}
.al-ico-forward {
  &:before {
    content: $al-ico-forward;
  }
}
.al-ico-copy-to-clipboard {
  &:before {
    content: $al-ico-copy-to-clipboard;
  }
}
.al-ico-card-example {
  &:before {
    content: $al-ico-card-example;
  }
}
.al-ico-bold {
  &:before {
    content: $al-ico-bold;
  }
}
.al-ico-italic {
  &:before {
    content: $al-ico-italic;
  }
}
.al-ico-heading {
  &:before {
    content: $al-ico-heading;
  }
}
.al-ico-quote {
  &:before {
    content: $al-ico-quote;
  }
}
.al-ico-link {
  &:before {
    content: $al-ico-link;
  }
}
.al-ico-code {
  &:before {
    content: $al-ico-code;
  }
}
.al-ico-list-bulleted {
  &:before {
    content: $al-ico-list-bulleted;
  }
}
.al-ico-list-numbered {
  &:before {
    content: $al-ico-list-numbered;
  }
}
.al-ico-me {
  &:before {
    content: $al-ico-me;
  }
}
.al-ico-team {
  &:before {
    content: $al-ico-team;
  }
}
.al-ico-task-desc {
  &:before {
    content: $al-ico-task-desc;
  }
}
.al-ico-dots {
  &:before {
    content: $al-ico-dots;
  }
}
.al-ico-move-to {
  &:before {
    content: $al-ico-move-to;
  }
}
.al-ico-abort {
  &:before {
    content: $al-ico-abort;
  }
}
.al-ico-extend {
  &:before {
    content: $al-ico-extend;
  }
}
.al-ico-reset-exp {
  &:before {
    content: $al-ico-reset-exp;
  }
}
.al-ico-tag {
  &:before {
    content: $al-ico-tag;
  }
}
.al-ico-shared-item {
  &:before {
    content: $al-ico-shared-item;
  }
}
.al-ico-restore {
  &:before {
    content: $al-ico-restore;
  }
}
.al-ico-workers {
  &:before {
    content: $al-ico-workers;
  }
}
.al-ico-dots-v-menu {
  &:before {
    content: $al-ico-dots-v-menu;
  }
}
.al-ico-d-menu-down {
  &:before {
    content: $al-ico-d-menu-down;
  }
}
.al-ico-d-menu-up {
  &:before {
    content: $al-ico-d-menu-up;
  }
}
.al-ico-slash {
  &:before {
    content: $al-ico-slash;
  }
}
.al-ico-info-circle {
  &:before {
    content: $al-ico-info-circle;
  }
}
.al-ico-annotate {
  &:before {
    content: $al-ico-annotate;
  }
}
.al-ico-task-desc-outline {
  &:before {
    content: $al-ico-task-desc-outline;
  }
}
.al-ico-manage-queue {
  &:before {
    content: $al-ico-manage-queue;
  }
}
.al-ico-enqueue {
  &:before {
    content: $al-ico-enqueue;
  }
}
.al-ico-dequeue {
  &:before {
    content: $al-ico-dequeue;
  }
}
.al-ico-applications {
  &:before {
    content: $al-ico-applications;
  }
}
.al-ico-ico-chevron-up {
  &:before {
    content: $al-ico-ico-chevron-up;
  }
}
.al-ico-ico-chevron-down {
  &:before {
    content: $al-ico-ico-chevron-down;
  }
}
.al-ico-no-data-graph {
  &:before {
    content: $al-ico-no-data-graph;
  }
}
.al-ico-no-scatter-graph {
  &:before {
    content: $al-ico-no-scatter-graph;
  }
}
.al-ico-auto-refresh-play .path1 {
  &:before {
    content: $al-ico-auto-refresh-play-path1;
    color: $blue-300; // don't overwrite
  }
}
.al-ico-auto-refresh-play .path2 {
  &:before {
    content: $al-ico-auto-refresh-play-path2;
    margin-left: -1em;
    color: $neon-yellow; // don't overwrite
  }
}
.al-ico-auto-refresh-pause .path1 {
  &:before {
    content: $al-ico-auto-refresh-pause-path1;
    color: $blue-300; // don't overwrite
  }
}
.al-ico-auto-refresh-pause .path2 {
  &:before {
    content: $al-ico-auto-refresh-pause-path2;
    margin-left: -1em;
    color: $neon-yellow; // don't overwrite
    // color: rgb(211, 253, 0);
  }
}
.al-ico-sqr-ok {
  &:before {
    content: $al-ico-sqr-ok;
  }
}
.al-ico-sqr-cancel {
  &:before {
    content: $al-ico-sqr-cancel;
  }
}
.al-ico-queue-lg {
  &:before {
    content: $al-ico-queue-lg;
  }
}
.al-ico-started-lg {
  &:before {
    content: $al-ico-started-lg;
  }
}
.al-ico-admin {
  &:before {
    content: $al-ico-admin;
  }
}
.al-ico-projects-outlined {
  &:before {
    content: $al-ico-projects-outlined;
  }
}
.al-ico-datasets-outlined {
  &:before {
    content: $al-ico-datasets-outlined;
  }
}
.al-ico-hide {
  &:before {
    content: $al-ico-hide;
  }
}
.al-ico-show {
  &:before {
    content: $al-ico-show;
  }
}
.al-ico-metadata {
  &:before {
    content: $al-ico-metadata;
  }
}
.al-ico-filter-reset .path1 {
  &:before {
    content: $al-ico-filter-reset-path1;
    color: $blue-300; // don't overwrite
  }
}
.al-ico-filter-reset .path2 {
  &:before {
    content: $al-ico-filter-reset-path2;
    margin-left: -1em;
    color: lighten($failed-red, 10%); // don't overwrite
    // color: rgb(237, 138, 131);
  }
}
.al-ico-version-label {
  &:before {
    content: $al-ico-version-label;
  }
}
.al-ico-plugin {
  &:before {
    content: $al-ico-plugin;
  }
}
.al-ico-abort-all {
  &:before {
    content: $al-ico-abort-all;
  }
}
.al-ico-refresh {
  &:before {
    content: $al-ico-refresh;
  }
}
.al-ico-rocket {
  &:before {
    content: $al-ico-rocket;
  }
}
.al-ico-logout {
  &:before {
    content: $al-ico-logout;
  }
}
.al-ico-settings-alert .path1 {
  &:before {
    content: $al-ico-settings-alert-path1;
    color: $black; // don't overwrite
  }
}
.al-ico-settings-alert .path2 {
  &:before {
    content: $al-ico-settings-alert-path2;
    margin-left: -1em;
    color: $neon-yellow; // don't overwrite
    // color: rgb(211, 255, 0);
  }
}
.al-ico-platform {
  &:before {
    content: $al-ico-platform;
  }
}
.al-ico-creditcard {
  &:before {
    content: $al-ico-creditcard;
  }
}
.al-ico-star {
  &:before {
    content: $al-ico-star;
  }
}
.al-ico-email-check {
  &:before {
    content: $al-ico-email-check;
  }
}
.al-ico-slack {
  &:before {
    content: $al-ico-slack;
  }
}
.al-ico-youtube {
  &:before {
    content: $al-ico-youtube;
  }
}
.al-ico-lock {
  &:before {
    content: $al-ico-lock;
  }
}
.al-ico-lock-open {
  &:before {
    content: $al-ico-lock-open;
  }
}
.al-ico-no-code {
  &:before {
    content: $al-ico-no-code;
  }
}
.al-ico-calendar-checked {
  &:before {
    content: $al-ico-calendar-checked;
  }
}
.al-ico-no-source {
  &:before {
    content: $al-ico-no-source;
  }
}
.al-ico-arrow-up {
  &:before {
    content: $al-ico-arrow-up;
  }
}
.al-ico-arrow-down {
  &:before {
    content: $al-ico-arrow-down;
  }
}
.al-ico-error-circle {
  &:before {
    content: $al-ico-error-circle;
  }
}
.al-ico-pipelines {
  &:before {
    content: $al-ico-pipelines;
  }
}
.al-ico-console {
  &:before {
    content: $al-ico-console;
  }
}
.al-ico-link-arrow {
  &:before {
    content: $al-ico-link-arrow;
  }
}
.al-ico-broken-file {
  &:before {
    content: $al-ico-broken-file;
  }
}
.al-ico-run {
  &:before {
    content: $al-ico-run;
  }
}
.al-ico-table-view {
  &:before {
    content: $al-ico-table-view;
  }
}
.al-ico-experiment-view {
  &:before {
    content: $al-ico-experiment-view;
  }
}
.al-ico-code-square {
  &:before {
    content: $al-ico-code-square;
  }
}
.al-ico-video {
  &:before {
    content: $al-ico-video;
  }
}
.al-ico-less-than {
  &:before {
    content: $al-ico-less-than;
  }
}
.al-ico-greater-than {
  &:before {
    content: $al-ico-greater-than;
  }
}
.al-ico-eye-outline {
  &:before {
    content: $al-ico-eye-outline;
  }
}
.al-ico-csv {
  &:before {
    content: $al-ico-csv;
  }
}
.al-ico-status-draft {
  &:before {
    content: $al-ico-status-draft;
  }
}
.al-ico-status-published {
  &:before {
    content: $al-ico-status-published;
  }
}
.al-ico-status-aborted-sec {
  &:before {
    content: $al-ico-status-aborted-sec;
  }
}
.al-ico-status-pending {
  &:before {
    content: $al-ico-status-pending;
  }
}
.al-ico-status-skipped {
  &:before {
    content: $al-ico-status-skipped;
  }
}
.al-ico-status-cached {
  &:before {
    content: $al-ico-status-cached;
  }
}
.al-ico-status-executed {
  &:before {
    content: $al-ico-status-executed;
  }
}
.al-ico-status-running {
  &:before {
    content: $al-ico-status-running;
  }
}
.al-ico-status-failed {
  &:before {
    content: $al-ico-status-failed;
  }
}
.al-ico-status-aborted {
  &:before {
    content: $al-ico-status-aborted;
  }
}
.al-ico-status-completed {
  &:before {
    content: $al-ico-status-completed;
  }
}

