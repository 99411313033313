@use "sass:math";
@use "sass:meta";

// colors
$white: #fff;
$blue-25: #f9fafb;
$blue-50: #f2f4fc; //242,244,252
$blue-100: #dce0ee; //220,224,238
$blue-200: #c3cdf0; //195,205,240
$blue-250: #A4ADCD;
$blue-280: #a7b2d8;
$blue-300: #8492c2; //132,146,19
$blue-400: #5a658e; //90,101,142
$blue-450: #657099;
$blue-480: #707ba3;
$blue-500: #384161; //56,65,97
$blue-550: #47527A;
$blue-600: #2c3246; //44,50,70
$blue-650: #24293c;
$blue-700: #202432; //32,36,50
$blue-800: #1a1e2c; //26,30,44
$blue-900: #141722; //20,23,34
$blue-950: #0d0e15; //20,23,34
$black: #000000;

$cloudy-blue: #d4d6e0;
$cloudy-blue-two: #ced1dc;
$dark-grey-blue-three: #1e2ce1;
$neon-yellow: #d3ff00;
$neon-green: #82dd22;
$neon-yellow-hover: #c6ef06;
$purple: #4d66ff;

$border-line-color-dark: rgba(0, 0, 0, 0.1);
$dark-border: #303443;
$completed-blue: #009aff;
$blue-sky: #4a90e2;
$pending-gray: $blue-300;
$failed-red: #d65b52;
$empty-grey: #313955;
$running-green: #50e3c3;
$turquoise: #14aa8c;
$running-green-contrast: #24463f;

$orangada: #fd8934;
$separator-line: #ededed;
$dataset_import: #f57e23;
$testing: #f8e71c;
$training: #f57e23;
$grey-purple: #7480aa;
$strong-red: #ff001f;
$header-backgroud: $blue-900;

$pipeline-pending: $blue-400;
$pipeline-queued: #21b553;
$pipeline-skipped: #cc9054;
$pipeline-cached: #9e54cc;
$pipeline-executed: #4040d5;
$pipeline-running: $turquoise;
$pipeline-failed: #d65b52;
$pipeline-aborted: $completed-blue;
$pipeline-completed: $completed-blue;

$error-dark: #ed8a83;

$info-color: #0080ff;
$success-color: #326C34;
$error-color: #de3232;
$warn-color: #ffa301;

$level-colors: (
  info: $info-color,
  success: $success-color,
  warn: $warn-color,
  error: $error-color,
);

$severity-colors: (
  0: #21B553,
  1: #4EB243,
  2: #7BAE32,
  3: #A6AA21,
  4: #D2A611,
  5: #FFA301,
  6: #F98D0B,
  7: #F17514,
  8: #EB5F1E,
  9: #E54928,
  10: #DE3232,
);

$dark-grey-blue: #39405f;
$light-periwinkle-two: #c1cdf3;
$faint-gray: #f4f7ff;


$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$mat-allegro: (
  50: $blue-50,
  100: $blue-100,
  200: $blue-200,
  300: $blue-300,
  400: $blue-400,
  500: $blue-500,
  600: $blue-600,
  700: $blue-700,
  800: $blue-800,
  900: $blue-900,
  A100: $blue-250,
  A200: $blue-450,
  A400: $blue-480,
  A700: $blue-550,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);







// charts
$chart-background: $black;

// padding
$spacer: 1rem; // TODO:
$spacers: (); // TODO:
$spacers: map-merge((// TODO:
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);

//sizes
$sizes: (); // TODO:
$sizes: map-merge((// TODO:
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
), $sizes);

// borders
$line-height-lg: 1; // TODO:
$line-height-sm: 0.5; // TODO:

$line-color: $blue-600; // TODO:

$border-width: 1px; // TODO:
//$border-color: $gray-300; // TODO:

$border-radius: .25rem; // TODO:
$border-radius-lg: .3rem; // TODO:
$border-radius-sm: .2rem; // TODO:

// fonts
$font-family-base: 'Heebo', sans-serif; // Assumes the browser default, typically `16px`
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-default-color: $blue-100; // Assumes the browser default, typically `16px`

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * .875);

$font-weight-normal: 400; // TODO:
$font-weight-base: $font-weight-normal; // TODO:
$font-weight-light: 300; // TODO:
$font-weight-bold: 700; // TODO:

$nav-bar-height: 64px;
$nav-bar-bg: $blue-900;
$side-bar-close-width: 64px;
$side-bar-open-width: 220px;

//transitions
$transition-slow: 0.6s;
$transition-fast: 0.3s;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000;
$zindex-sticky: 999; //$zindex-sticky: 1020;
$zindex-colorpicker: 1001;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// card
$card-border-radius: 6px;
$card-bg: $blue-500;
$card-footer-bg: $blue-600;
$card-footer-font-color: $blue-300;
$card-header-color: $blue-300;

// chips
$chip-border-radius: 12px;
$chip-bg-opacity: 0.1;
$chip-height: 15px;

// circle counter

// table
$table-cell-padding: 0 0.75rem;
$table-cell-padding-sm: .3rem;

$table-bg: $blue-700;
$table-even-bg: $blue-800;
$table-hover-bg: $blue-600;
$table-active-bg: $table-hover-bg;

$table-header-bg: $blue-600;
$table-header-color: $blue-300;

$table-border-radius: 2px; // TODO
$table-border-color: transparent; // TODO

$body-bg-color: $blue-700;

$assets-icons-path: 'app/webapp-common/assets/icons';

// dialog
$leaf-height: 55vh;
$generic-base-z-index: 99999;

// font sizes
$font-size-small: 12px;
$font-size-regular: 14px;
